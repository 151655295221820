import * as React from "react"
import {Box, Card, CardContent, Grid, Paper} from "@mui/material";
import {GatsbyImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import Prijs from "../prijs";
import Container from "@mui/material/Container";
import {Slogan} from "../typography";
import Bouwnummers from "../bouwnummers";
import Specificaties from "./specificaties";
import {Bouwnummer, Bouwtype} from "../../graphqlTypes";
import {graphql} from "gatsby";

interface Props {
    title: string
    bouwtypes: Bouwtype[]
    bouwnummers: Bouwnummer[]
}
const BouwtypeHeader = ({title, bouwtypes, bouwnummers}: Props) => {
    const prijsVanaf = Math.min(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.koopaanneemsomVanaf || 0))
    const image = bouwtypes[0].hoofdfoto?.localFile.childImageSharp.gatsbyImageData
    return (
       <>
           <Box
               position={'relative'}
           >
               {image &&
                   <GatsbyImage
                       image={image}
                       alt={'water'}
                   />
               }
               <Paper
                   sx={{
                       position: {md: 'absolute'},
                       marginBotttom: 3,
                       top: 20,
                       right: 20,
                       paddingX: 3,
                       paddingY: 1
                   }}
               >
                   <Typography
                       color={'primary.main'}
                       fontWeight={'bold'}
                       fontSize={'1.5rem'}
                   >
                       V.A. <Prijs prijs={prijsVanaf} /> v.o.n.
                   </Typography>
               </Paper>
           </Box>
           <Container
               sx={{
                   transform: {md: 'translateY(-50%)'},
               }}
           >
               <Card
                   sx={{
                       padding: 3,
                   }}
                   elevation={3}
               >
                   <CardContent>
                       <Grid container spacing={3}>
                           <Grid item xs={12} md={6}>
                               <Box
                                   marginBottom={2}
                               >
                                   <Slogan>
                                       Woningimpressie
                                   </Slogan>
                               </Box>
                               <Typography variant={'h2'}>
                                   {title}
                               </Typography>
                           </Grid>
                           <Grid item xs={12} md={3}>
                               <Typography
                                   color={'primary.main'}
                                   fontWeight={'bold'}
                               >
                                   Specificaties:
                               </Typography>
                               <Box
                                   marginY={1}
                                   color={'primary.main'}
                               >
                                   <Specificaties bouwtypes={bouwtypes} />
                               </Box>
                           </Grid>
                       </Grid>
                   </CardContent>
               </Card>
           </Container>
       </>
    )
}

export const query = graphql`
    fragment BouwtypeHeader on Bouwtype {
        algemeen {
            omschrijving
            koopaanneemsomVanaf
        }
        hoofdfoto {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        aspectRatio: 1.6
                    )
                }
            }
            omschrijving
        }
    }
`

export default BouwtypeHeader