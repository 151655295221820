import * as React from "react"
import {Box, Button, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {sectionPadding, textMaxWidth} from "../../theme";
import {graphql, Link} from "gatsby";
import {Bouwnummer, Bouwtype} from "../../graphqlTypes";
import GevelaanzichtBlok1 from "../../components/gevelaanzichten/blok1";
import GevelaanzichtBlok5 from "../../components/gevelaanzichten/blok5";
import Situatie from "../../components/situatie";
import BouwtypeHeader from "../../components/bouwtype/header";
import Plattegronden from "../../components/bouwtype/plattegronden";
import Fotos from "../../components/bouwtype/fotos";
import Oppervlakte from "../../components/oppervlakte";

interface Props {
    data: PageQueryData
}
export default ({data}: Props) => {
    return (
        <article>
            <BouwtypeHeader
                title={'Blok 1 & 5'}
                bouwtypes={data.allBouwtype.nodes}
                bouwnummers={data.allBouwnummer.nodes}
            />
            <Box
                sx={{
                    paddingTop: {xs: sectionPadding, md: 0},
                    paddingBottom: sectionPadding
                }}
                paddingY={sectionPadding}
            >
                <Container>
                    <Box
                        maxWidth={textMaxWidth}
                    >
                        <Typography
                            paragraph
                            color={'primary.main'}
                            fontWeight={'medium'}
                        >
                            Blok 1 omvat royale drie-onder-één-kapwoningen. Blok 5 siert het woonplan met 5 rijwoningen. De in totaal 8 woningen variëren van 127 tot 131m2 woonoppervlak. Elke woning staat op een ruim perceel waarbij de tuinen van Blok 1 op het oosten zijn gericht en die van Blok 5 op het zuiden.
                        </Typography>
                        <Typography
                            paragraph
                            color={'primary.main'}
                            fontWeight={'medium'}
                        >
                            De hoekwoningen van beide blokken en bouwnummer 2 hebben een eigen parkeerplaats. De laatste in rij is voorzien van een topgevel. Kenmerkend voor beide blokken is het authentieke en toch moderne aangezicht van de specifieke gevelbouw en ‘steense’ kleuren met veel gevelaccenten. Alleszins fraai en uitnodigend.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <StaticImage src={'../../images/impressies/Interieur-BNR-1.jpg'} alt={'interieur bouwnummer 1'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper
                                sx={{
                                    backgroundColor: 'secondary.main',
                                    padding: 4,
                                    height: '100%'
                                }}

                            >
                                <Typography
                                    variant={'h4'}
                                    color={'common.white'}
                                >
                                    Kenmerken
                                </Typography>
                                <Typography
                                    color={'common.white'}
                                >
                                    <Box
                                        component={'ul'}
                                    >
                                        <li>
                                            Drie royale verdiepingen
                                        </li>
                                        <li>
                                            Beukmaat 5.70m
                                        </li>
                                        <li>
                                            Woonoppervlakte variërend van <Oppervlakte oppervlakte={'127 tot 131'} />
                                        </li>
                                        <li>
                                            Perceel variërend van <Oppervlakte oppervlakte={'155 tot 219'}/>
                                        </li>
                                        <li>
                                            Ruime woonkamer en lichte, open keuken
                                        </li>
                                        <li>
                                            Dubbele openslaande deuren naar de tuin
                                        </li>
                                        <li>
                                            3 (slaap-)kamers op de eerste verdieping
                                        </li>
                                        <li>
                                            Ruime, vrij indeelbare zolder
                                        </li>
                                        <li>
                                            Tuin inclusief eigen berging
                                        </li>
                                        <li>
                                            Voorzien van warmtepomp en zonnepanelen
                                        </li>
                                    </Box>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Plattegronden bouwtypes={data.allBouwtype.nodes} />
            <Box>
                <Container
                >
                    <Box
                        sx={{
                            paddingY: sectionPadding*2,
                            backgroundColor: 'secondary.light',
                        }}
                        textAlign={'center'}
                    >
                        <Box
                            maxWidth={textMaxWidth}
                            marginX={'auto'}
                        >
                            <Typography variant={'h2'}>
                                Voel je direct thuis
                            </Typography>
                            <Typography paragraph>
                                Dit type woningen voldoet aan alle wensen die gezinnen hebben. Een ruime woonkamer, fijne keuken en openslaande deuren naar de tuin. De indeling op de eerste verdieping biedt ruimte die moderne gezinnen nodig hebben: een mooie badkamer en drie fijne slaapkamers.
                            </Typography>
                            <Typography paragraph>
                                Nog een verdieping hoger vindt u een vrij indeelbare zolder met de technische ruimte en opstelplaats voor een wasmachine en droger. Creëer een extra hobby- of speelruimte, maar een ruime werkplek is uiteraard ook mogelijk! Zo krijgt elk vertrek van deze ruime woning zijn eigen invulling.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Fotos bouwtypes={data.allBouwtype.nodes} />
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Typography
                        variant={'h2'}
                        textAlign={'center'}
                    >
                        Gevelaanzichten
                    </Typography>
                    <Grid
                        container
                        spacing={3}
                        alignItems={'stretch'}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <GevelaanzichtBlok1 />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={7}
                            display={'flex'}
                            flexDirection={'column'}
                        >
                            <GevelaanzichtBlok5 />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
            >
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Situatie Daverhof
                </Typography>
                <Container>
                    <Situatie bouwnummers={data.allBouwnummer.nodes.filter(bouwnummer => bouwnummer.fields.coordinaten)} />
                </Container>
            </Box>
            <Box
                paddingY={sectionPadding}
                textAlign={'center'}
            >
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Bekijk meer woningen
                </Typography>
                <Button
                    component={Link}
                    to={'/woningen/blok2-en-3'}
                    variant={'contained'}
                    sx={{
                        marginX: 1
                    }}
                >
                    Blok 2 + 3
                </Button>
                <Button
                    component={Link}
                    to={'/woningen/blok4-en-6'}
                    variant={'contained'}
                    sx={{
                        marginX: 1
                    }}
                >
                    Blok 4 + 6
                </Button>
            </Box>
        </article>
    )
}

interface PageQueryData {
    allBouwnummer: {
        nodes: Bouwnummer[]
    }
    allBouwtype: {
        nodes: Bouwtype[]
    }
}

export const query = graphql`
    query {
        allBouwnummer(filter: {bouwtype: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}}) {
            nodes {
                id
                ...BouwnummerArea
            }
        }
        allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}) {
            nodes {
                id
                ...BouwtypeHeader
                ...BouwtypePlattegronden
                ...BouwtypeSpecificaties
            }
        }
    }
`
