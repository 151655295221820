import * as React from "react"
import {Bouwtype} from "../../graphqlTypes";
import Typography from "@mui/material/Typography";
import Oppervlakte from "../oppervlakte";
import {Box, Grid} from "@mui/material";
import {sectionPadding} from "../../theme";
import {GatsbyImage} from "gatsby-plugin-image";

interface Props {
    bouwtypes: Bouwtype[]
}
const Fotos = ({bouwtypes}: Props) => {
    return (
        <Box
            paddingY={sectionPadding}
        >
            <Grid
                container
                spacing={5}
                justifyContent={'center'}
            >
                {bouwtypes.map(bouwtype =>
                    bouwtype.hoofdfoto &&
                    <Grid
                        key={bouwtype.id}
                        item
                        xs={12}
                        md={6}
                        textAlign={'center'}
                    >
                        <GatsbyImage
                            alt={''}
                            image={bouwtype.hoofdfoto.localFile.childImageSharp.gatsbyImageData}
                        />
                        <Typography
                            textAlign={'center'}
                        >
                            {bouwtype.algemeen.omschrijving}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default Fotos
