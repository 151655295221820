import * as React from "react"
import {Bouwtype, Media} from "../../graphqlTypes";
import Typography from "@mui/material/Typography";
import {Box, Grid} from "@mui/material";
import {sectionPadding} from "../../theme";
import Container from "@mui/material/Container";
import {graphql} from "gatsby";
import Plattegrond from "../plattegrond";

interface Props {
    bouwtypes: Bouwtype[]
}
const Plattegronden = ({bouwtypes}: Props) => {
    const plattegronden = bouwtypes.reduce((result: Media[], value) => [...result, ...value.plattegronden || []], [])
    return (
        <>
            {plattegronden.length > 0 &&
                <Box
                    component={'section'}
                    padding={sectionPadding}
                >
                    <Container>
                        <Typography
                            variant={'h2'}
                            textAlign={'center'}
                        >
                            Indelingen
                        </Typography>
                        <Grid
                            container
                            justifyContent={'center'}
                            spacing={5}
                        >
                            {plattegronden?.map(plattegrond =>
                                <Grid
                                    key={plattegrond.id}
                                    item
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    textAlign={'center'}
                                >
                                    <Plattegrond plattegrond={plattegrond} />
                                </Grid>
                            )}
                        </Grid>
                    </Container>
                </Box>
            }
        </>
    )
}

export const query = graphql`
    fragment BouwtypePlattegronden on Bouwtype {
        plattegronden {
            ...Plattegrond
        }
    }
`

export default Plattegronden
